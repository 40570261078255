import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import theme from '../theme';

import BnWSection from '../components/BnWSection';
import BnWContent from '../components/BnWContent';

// eslint-disable-next-line
export const TermsCondTemplate = ({ title, content, contentComponent }) => {
	const PageContent = contentComponent || Content;

	return (
		<BnWSection
			className='section'
			sx={{ boxShadow: 12, mx: { xs: 1, md: 8 }, mt: { xs: 1, md: 6 } }}
			elevation={3}
		>
		<BnWContent
				sx={{ color: theme.palette.background.default, px: 2, py: { xs: 2, md: 8 }, m: { xs: 0, sm: '12px 64px 48px' } }}
			>
				<PageContent className='content' content={content} />
			</BnWContent>
		</BnWSection>
	);
};

TermsCondTemplate.propTypes = {
	title: PropTypes.string.isRequired,
	content: PropTypes.string,
	contentComponent: PropTypes.func,
};

const TermsCond = ({ data }) => {
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<TermsCondTemplate
				contentComponent={HTMLContent}
				title={post.frontmatter.title}
				content={post.html}
			/>
		</Layout>
	);
};

TermsCond.propTypes = {
	data: PropTypes.object.isRequired,
};

export default TermsCond;

export const TermsCondQuery = graphql`
	query TermsCond($id: String!) {
		markdownRemark(id: { eq: $id }) {
			html
			frontmatter {
				title
			}
		}
	}
`;
